import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getcurrentTripId } from '../../selectors/ridingTrip';
import { getUser } from '../../selectors/auth';
import { getTrip } from '../../selectors/trips';
import actions from '../../actions';
import { NATIVE_ACTIONS } from '../../../lib/models/native';

export const useConnect = () => {
  const dispatch = useDispatch();
  return {
    user: useSelector(getUser),
    trip: useSelector(getTrip(useSelector(getcurrentTripId))),
    loadRoute: routeId => dispatch(actions.routes.loadOne(routeId)),
    startNavigation: payload =>
      dispatch(actions.native.postMessage({ type: NATIVE_ACTIONS.START_NAVIGATION_WITH_ROUTE, payload })),

    end: id => dispatch(actions.trippers.end(id)),
    postMessage: message => dispatch(actions.native.postMessage(message)),
  };
};

export const useEndDialog = () => {
  const [endDialog, setEndDialog] = useState(false);
  return [endDialog, setEndDialog];
};
