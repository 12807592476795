import {
  path,
  prop,
  compose,
  head,
  pluck,
  values,
  reduce,
  propOr,
  filter,
  addIndex,
  set,
  lensPath,
  prepend,
  tail,
  over,
  dec,
  subtract,
  init,
  move,
  insert,
  or,
  and,
  append,
  remove,
  pathOr,
} from 'ramda';
import { getFilter, getPosition, getSelectedRoute } from '../selectors/tunnel';
import { getAddress } from '../mapbox';
import routes from './routes'; // eslint-disable-line
import bikers from './bikers'; // eslint-disable-line
import trips from './trips'; // eslint-disable-line
import { postMessage } from './native'; // eslint-disable-line
import { request } from './utils';
import { SIGNED_OUT } from './auth'; // eslint-disable-line
import { getEntitlements, getUser } from '../selectors/auth';
import trippers, { TRIPPER_ADDED } from './trippers'; // eslint-disable-line
import { checkIfLastItemOfLoop, getPointsCount, routeIsEmpty } from '../../lib/models/routes';
import { MODE, getTripType, isDraft } from '../../lib/models/trips';
import { STATUS } from '../../lib/models/wheels';
import { distance } from '../utils/geolocation';
import { NATIVE_ACTIONS } from '../../lib/models/native';
import { isPremiumUser } from '../../lib/models/bikers';

const SET_ROUTE = '@trip:SET_ROUTE';
const SET_TRIP_TYPE = '@trip:SET_TRIP_TYPE';
const SET_POSITION = '@trip:SET_POSITION';
const SET_TRIP = '@trip:SET_TRIP';

const SET_FILTER = '@trip:SET_FILTER';
const SET_DIRECTIONS = '@trip:SET_DIRECTIONS';
const SET_INFO = '@trip:SET_INFO';
const TRIPS_LOADED = '@trip:TRIPS_LOADED';
const TRIPPERS_LOADED = '@trip:TRIPPERS_LOADED';
const SET_STEP = '@trip:SET_STEP';
const SET_TUNNEL_MODE = '@trip:SET_TUNNEL_MODE';
const CLEAR_TUNNEL = '@trip:CLEAR_TUNNEL';
const SAME_DAY_TRIPS_LOADED = '@trip:SAME_DAY_TRIPS_LOADED';
const SET_STARTING_ADDRESS = '@trip:SET_STARTING_ADDRESS';

export const DELETE_STEP = '@routes:DELETE_STEP';
export const UPDATE_STEP = '@routes:UPDATE_STEP';
export const UPDATE_INDEX = '@routes:UPDATE_INDEX';
export const ADD_STEP = '@routes:ADD_STEP';
export const UPDATE_LOOP = '@routes:UPDATE_TYPE';
export const UPDATE_HIGHWAY = '@routes:UPDATE_HIGHWAY';
export const INITIATE_ROUTE = '@routes:INITIATE_ROUTE';

const reducer = (state = {}, action) => {
  let data;
  switch (action.type) {
    case SET_POSITION:
      if (
        distance(
          pathOr(0, ['position', 'latitude'], state),
          pathOr(0, ['position', 'longitude'], state),
          action.position.latitude,
          action.position.longitude,
        ) > 0.2 ||
        !state.position
      )
        return {
          ...state,
          position: { ...state.position, ...action.position },
        };
      return { ...state };
    case SET_FILTER:
      return {
        ...state,
        filter: action.filter,
      };
    case SET_ROUTE:
      return {
        ...state,
        route: action.route,
      };
    case SET_TRIP:
      return {
        ...state,
        trip: action.trip,
      };
    case SET_DIRECTIONS:
      return {
        ...state,
        directions: action.directions,
      };
    case SET_INFO:
      return {
        ...state,
        info: action.info,
      };
    case SET_STARTING_ADDRESS:
      return {
        ...state,
        startingAddress: action.startingAddress,
      };
    case TRIPPERS_LOADED:
      // return {
      //   ...state,
      //   trippers: action.trippers,
      data = compose(
        reduce((acc, v) => ({ ...acc, [v.bikerId]: v }), {}),
        propOr([], 'trippers'),
      )(action);
      return { ...state, trippers: { data } };
    case TRIPS_LOADED:
      data = compose(
        reduce((acc, v) => ({ ...acc, [v._id]: v }), {}),
        propOr([], 'trips'),
      )(action);
      return { ...state, trips: { data } };

    case SET_TRIP_TYPE:
      return {
        ...state,
        trip: { ...state.trip, type: action.tripType },
      };
    case SET_STEP:
      return {
        ...state,
        currentStep: action.step,
      };
    case SET_TUNNEL_MODE:
      return {
        ...state,
        currentMode: action.mode,
      };
    case SAME_DAY_TRIPS_LOADED:
      return {
        ...state,
        sameDayTrips: action.sameDayTrips,
      };
    case CLEAR_TUNNEL:
      return {
        currentMode: MODE.create,
        currentStep: 0,
      };
    case SIGNED_OUT:
      return { ...state, data: {} };

    case DELETE_STEP:
    case UPDATE_STEP:
    case UPDATE_INDEX:
    case ADD_STEP:
    case UPDATE_LOOP:
    case UPDATE_HIGHWAY:
      return {
        ...state,
        route: { ...action.route },
      };
    default:
      return state;
  }
};

// const join = ({ _id: tripId }) => dispatch =>
//   request(dispatch, { method: 'trips:join', tripId }).then(wheel => dispatch({ type: BIKER_JOINED, wheel, tripId }));
//
// const leave = ({ _id: tripId }) => dispatch =>
//   request(dispatch, { method: 'trips:leave', tripId }).then(wheel => dispatch({ type: BIKER_LEFT, wheel }));

// const getActiveBikers = ({ _id: id }) => dispatch =>
//   request(dispatch, { method: 'trips:getActiveBikers', id }).then(bikers =>
//     dispatch({ type: ACTIVE_BIKERS_LOADED, bikers }),
//   );

const setPosition = position => dispatch =>
  getAddress([position.longitude, position.latitude]).then(response => {
    const address = compose(
      prop('place_name'),
      head,
      path(['data', 'features']),
    )(response);

    dispatch({
      type: SET_POSITION,
      position: {
        ...position,
        address,
      },
    });
    return address;
  });
const clearTunnel = () => ({
  type: CLEAR_TUNNEL,
});

const setFilter = tripFilter => ({
  type: SET_FILTER,
  filter: tripFilter,
});

const setRoute = route => ({
  type: SET_ROUTE,
  route,
});

const setTrip = trip => ({
  type: SET_TRIP,
  trip,
});

const setStep = step => ({
  type: SET_STEP,
  step,
});

const setMode = mode => ({
  type: SET_TUNNEL_MODE,
  mode,
});

const setDirections = directions => dispatch =>
  dispatch({
    type: SET_DIRECTIONS,
    directions,
  });

const getTrippers = (id, statuses) => dispatch =>
  request(dispatch, { method: 'trips:getTrippers', id, statuses }).then(trippers => {
    dispatch({ type: TRIPPERS_LOADED, trippers });
    return trippers;
  });

const joinTrip = (tripId, isVisitor = false) => (dispatch, getState) =>
  request(dispatch, { method: 'trippers:join', tripId, isVisitor }).then(tripper => {
    const state = getState();
    const user = getUser(state);
    const entitlements = getEntitlements(state);
    if (!isPremiumUser(user, entitlements)) dispatch(postMessage({ type: NATIVE_ACTIONS.SHOW_ADD }));
    dispatch({ type: TRIPPER_ADDED, tripper });
    return tripper;
  });

const setInfo = info => ({
  type: SET_INFO,
  info,
});

const loadRoutes = () => (dispatch, getState) => {
  const state = getState();
  const { latitude, longitude } = getPosition(state);
  const { searchRange, length, duration, tripDifficulty } = getFilter(state);
  return dispatch(
    routes.actions.loadAll({
      near: { point: { type: 'Point', coordinates: [longitude, latitude] }, maxDistance: searchRange },
      distances: length,
      difficulty: tripDifficulty,
      times: duration,
      publicOnly: true,
    }),
  );
};

const deleteLocation = index => (dispatch, getState) => {
  const state = getState();
  const currentRoute = getSelectedRoute(state);

  const filterIndexed = addIndex(filter);

  const route = {
    ...currentRoute,
    location: {
      type: path(['location', 'type'], currentRoute),
      features: compose(
        filterIndexed((elem, idx) => idx !== checkIfLastItemOfLoop(index, currentRoute)),
        path(['location', 'features']),
      )(currentRoute),
    },
  };
  dispatch({
    type: DELETE_STEP,
    route,
  });
};

const updateLocationIndex = (dragIndex, dropIndex) => (dispatch, getState) => {
  const state = getState();
  const currentRoute = getSelectedRoute(state);

  let route = over(
    lensPath(['location', 'features']),
    move(checkIfLastItemOfLoop(dragIndex, currentRoute), dropIndex),
    currentRoute,
  );

  if (and(or(dragIndex === 0, dropIndex === 0), currentRoute.loop)) {
    route = over(
      lensPath(['location', 'features']),
      compose(
        append(path(['location', 'features', 0], route)),
        init,
      ),
      route,
    );
  }

  if (and(or(dragIndex === dec(getPointsCount(route)), dropIndex === dec(getPointsCount(route))), currentRoute.loop)) {
    route = over(
      lensPath(['location', 'features']),
      compose(
        prepend(path(['location', 'features', dec(getPointsCount(route))], route)),
        tail,
      ),
      route,
    );
  }

  dispatch({
    type: UPDATE_INDEX,
    route,
  });
};

const addNewLocation = ({ latitude, longitude }) => (dispatch, getState) => {
  const state = getState();
  const currentRoute = getSelectedRoute(state);

  const newLocation = {
    type: 'Feature',
    properties: {},
    geometry: { type: 'Point', coordinates: [longitude, latitude] },
  };

  const route = over(
    lensPath(['location', 'features']),
    coords => {
      if (prop('loop', currentRoute) && !routeIsEmpty(currentRoute))
        return insert(dec(getPointsCount(currentRoute)), newLocation, coords);
      if (prop('loop', currentRoute) && routeIsEmpty(currentRoute)) {
        return compose(
          insert(dec(getPointsCount(currentRoute)), newLocation),
          insert(dec(getPointsCount(currentRoute)), newLocation),
        )(coords);
      }
      return append(newLocation, coords);
    },
    currentRoute,
  );
  dispatch({
    type: ADD_STEP,
    route,
  });
};

const deleteLastLocation = () => (dispatch, getState) => {
  const state = getState();
  const currentRoute = getSelectedRoute(state);
  const route = over(
    lensPath(['location', 'features']),
    coords => {
      if (prop('loop', currentRoute) && getPointsCount(currentRoute) === 2) return [];
      if (prop('loop', currentRoute)) return remove(subtract(getPointsCount(currentRoute), 2), 1, coords);
      return remove(dec(getPointsCount(currentRoute)), 1, coords);
    },
    currentRoute,
  );
  dispatch({
    type: ADD_STEP,
    route,
  });
};

const toggleLoop = loop => (dispatch, getState) => {
  const state = getState();
  const currentRoute = getSelectedRoute(state);

  let route = {
    ...currentRoute,
    loop,
  };
  if (loop) {
    route = over(lensPath(['location', 'features']), append(path(['location', 'features', 0], currentRoute)), route);
  } else {
    route = over(lensPath(['location', 'features']), init, route);
  }
  dispatch({
    type: UPDATE_LOOP,
    route,
  });
};
const toggleHighway = noHighway => (dispatch, getState) => {
  const state = getState();
  const currentRoute = getSelectedRoute(state);

  const route = {
    ...currentRoute,
    noHighway,
  };
  dispatch({
    type: UPDATE_HIGHWAY,
    route,
  });
};

const updateLocation = (index, coords) => (dispatch, getState) => {
  const state = getState();

  const currentRoute = getSelectedRoute(state);

  let route = set(
    lensPath(['location', 'features', index, 'geometry', 'coordinates']),
    [coords.longitude, coords.latitude],
    currentRoute,
  );

  if (and(index === 0, currentRoute.loop)) {
    route = set(
      lensPath(['location', 'features', dec(getPointsCount(route)), 'geometry', 'coordinates']),
      [coords.longitude, coords.latitude],
      route,
    );
  }

  if (and(index === dec(getPointsCount(route)), currentRoute.loop)) {
    route = set(
      lensPath(['location', 'features', 0, 'geometry', 'coordinates']),
      [coords.longitude, coords.latitude],
      route,
    );
  }

  dispatch({
    type: UPDATE_STEP,
    route,
  });
};
const updateInfo = (index, vals) => (dispatch, getState) => {
  const state = getState();
  const currentRoute = getSelectedRoute(state);

  const route = set(
    lensPath(['location', 'features', checkIfLastItemOfLoop(index, currentRoute), 'properties']),
    {
      ...path(['location', 'features', checkIfLastItemOfLoop(index, currentRoute), 'properties']),
      ...vals,
    },
    currentRoute,
  );

  dispatch({
    type: UPDATE_STEP,
    route,
  });
};
const loadTrips = ({ tripType }) => (dispatch, getState) => {
  const state = getState();
  const { latitude, longitude } = getPosition(state);
  const { searchRange, length, duration, tripDifficulty, when, type } = getFilter(state);
  const { _id } = getUser(state);
  const params = {
    near: { point: { type: 'Point', coordinates: [longitude, latitude] }, maxDistance: searchRange },
    distances: length,
    difficulty: tripDifficulty,
    times: duration,
    when,
    type,
    leaderId: _id,
    tripType,
  };
  const getTripLeaderIds = compose(
    values,
    pluck('leaderId'),
  );
  const getTripRouteIds = compose(
    values,
    pluck('routeId'),
  );
  request(dispatch, { method: 'trips:loadAll', ...params })
    .then(trips => {
      dispatch({ type: TRIPS_LOADED, trips });
      return trips;
    })
    .then(trips => {
      const leaderIds = getTripLeaderIds(trips);
      const routeIds = getTripRouteIds(trips);
      return Promise.all([
        dispatch(routes.actions.loadAll({ ids: routeIds })),
        dispatch(bikers.actions.loadAll({ ids: leaderIds })),
      ]);
    });
};

const initiateRoute = () => dispatch => {
  const route = {
    location: {
      type: 'FeatureCollection',
      features: [],
    },
  };

  dispatch({
    type: UPDATE_STEP,
    route,
  });
};

const loadSameDayTrips = when => dispatch => {
  const { pending, accepted, riding, suspended } = STATUS;
  request(dispatch, { method: 'bikers:loadTrips', statuses: [pending, accepted, riding, suspended], when }).then(
    ({ trips }) => {
      dispatch({ type: SAME_DAY_TRIPS_LOADED, sameDayTrips: trips });
      return trips;
    },
  );
};

const setStartingAddress = route => dispatch => {
  getAddress(path(['location', 'features', 0, 'geometry', 'coordinates'], route)).then(response => {
    const startingAddress = compose(
      prop('place_name'),
      head,
      path(['data', 'features']),
    )(response);
    dispatch({ type: SET_STARTING_ADDRESS, startingAddress });
  });
};
const setTripType = tripType => ({
  type: SET_TRIP_TYPE,
  tripType,
});

const loadTripFromId = id => (dispatch, getState) =>
  dispatch(trips.actions.loadOne(id)).then(trip => {
    const state = getState();
    const user = getUser(state);
    const tripType = getTripType(trip, user);

    dispatch(setTrip(trip));
    dispatch(trippers.actions.getTrippers(trip._id));
    dispatch({
      type: SET_TRIP_TYPE,
      tripType,
    });
    return dispatch(routes.actions.loadOne(trip.routeId)).then(route => {
      return { trip, route };
    });
  });

const loadTripInfo = (id, routeOnly) => dispatch =>
  dispatch(loadTripFromId(id)).then(({ route, trip }) => {
    dispatch(setRoute(route));

    if (!routeOnly)
      dispatch(
        setInfo({
          tripName: trip.name,
          tripClass: trip.tripClass,
          tripDate: isDraft(trip) ? null : new Date(trip.startAt),
          tripTime: isDraft(trip) ? null : new Date(trip.startAt),
          bikesMaxNumber: trip.maxBikers,
          autoValidate: trip.autoJoin,
          tripDescription: route.comment,
          status: route.status,
          isDraft: isDraft(trip),
          tripType: trip.tripType,
        }),
      );
  });

const actions = {
  setTrip,
  setPosition,
  loadRoutes,
  setFilter,
  setRoute,
  setDirections,
  setInfo,
  loadTrips,
  getTrippers,
  joinTrip,
  setTripType,
  setStep,
  setMode,
  clearTunnel,
  deleteLocation,
  updateLocation,
  updateLocationIndex,
  addNewLocation,
  toggleLoop,
  toggleHighway,
  updateInfo,
  initiateRoute,
  loadSameDayTrips,
  setStartingAddress,
  loadTripFromId,
  loadTripInfo,
  deleteLastLocation,
};

export default { reducer, actions };
