import { prop } from 'ramda';

import { createSelector } from 'reselect';

const root = prop('native');

export const getIsNative = createSelector(
  root,
  prop('isNative'),
);

export const getShowPremiumToast = createSelector(
  root,
  prop('showPremiumToast'),
);
