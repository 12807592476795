import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Switch from '@material-ui/core/Switch';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SettingsIcon from '@material-ui/icons/Settings';
import SearchIcon from '@material-ui/icons/Search';
import ShareIcon from '@material-ui/icons/Share';
import CloseIcon from '@material-ui/icons/Close';
import { propOr } from 'ramda';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Dialog from '../Dialog';
import Avatar from '../Avatar';
import useHeight from '../../hooks/useHeight';
import { AVATAR_SIZES, fullname, isFreeUser, ROLES } from '../../../lib/models/bikers';
import messages from './messages';
import useRoutes from '../../hooks/routes';
import { useLogoutDialog, useConnect, useNnotificationsRadius } from './hooks';
import SelectLocation from '../Notifications/SelectLocation';
import PassPremiumButton from '../Buttons/PassPremiumButton';

const drawerWidth = 360;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawerRoot: {
    width: `${drawerWidth}px`,
  },
  drawerPaper: () => ({
    width: `${drawerWidth}px`,
    height: 'auto',
    overflowX: 'hidden',
    background: theme.palette.grey[200],
    borderRadius: '0 20px 20px 0',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  }),
  name: {
    color: theme.palette.grey[700],
  },
  email: {
    color: theme.palette.grey[500],
    fontSize: '0.9em',
  },
  divider: {
    marginTop: theme.spacing(1),
  },
  buttonIcon: {
    marginRight: theme.spacing(2),
  },
  button: {
    minWidth: '100%',
    textAlign: 'initial',
    display: 'flex',
    justifyContent: 'start',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  menuPremiumLogo: {
    width: '30px',
    marginRight: theme.spacing(2),
    left: '10px',
  },
  premiumLogo: {
    width: '30px',
    marginRight: theme.spacing(2),
    position: 'absolute',
    left: '10px',
  },
  premiumButton: {
    marginTop: theme.spacing(2),
    background: 'linear-gradient(180deg, rgba(237,195,1,1) 0%, rgba(237,115,1,1) 100%)',
    borderRadius: '40px',
    minWidth: '75%',
  },
  switch: {
    switchBase: {
      color: theme.palette.success.main,
      '&$checked': {
        color: theme.palette.success.main,
      },
      '&$checked + $track': {
        backgroundColor: theme.palette.success.main,
      },
    },
    checked: {},
    track: {},

    '& .MuiSwitch-switchBase.Mui-checked': {
      color: theme.palette.success.main,
      '&:hover': {
        backgroundColor: `${theme.palette.success.main}44`,
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.palette.success.main,
    },
  },
}));
const MainMenu = ({ open, setDrawer, user }) => {
  const height = useHeight();
  const history = useHistory();
  const routes = useRoutes();
  const intl = useIntl();
  const { logout, updateBiker, isNative, askExpoToShareComot, entitlements } = useConnect();
  const [logoutDialog, setLogoutDialog] = useLogoutDialog();
  const [notificationsRadius, setNotificationsRadius] = useNnotificationsRadius();
  const classes = useStyles({ height });
  return (
    <Box className={classes.root} style={{ width: `${drawerWidth}px` }}>
      <SwipeableDrawer
        anchor="left"
        ModalProps={{
          disablePortal: true,
          BackdropProps: {
            classes: {
              root: classes.BackdropProps,
            },
          },
        }}
        open={open}
        onClose={() => setDrawer(false)}
        onOpen={() => setDrawer(true)}
        classes={{
          root: classes.drawerRoot,
          paper: classes.drawerPaper,
        }}
      >
        <>
          <Grid container direction="column">
            <Grid container item direction="row" spacing={2}>
              <Grid item>
                <Avatar biker={user} size={AVATAR_SIZES.large} />
              </Grid>
              <Grid item direction="column">
                <Grid item>
                  <Typography fontWeight="fontWeightMedium" className={classes.name}>
                    {propOr(fullname, 'userName', user)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography fontWeight="fontWeightMedium" color="default" className={classes.email}>
                    {propOr('', 'email', user)}
                  </Typography>
                </Grid>

                <Grid item></Grid>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />

            {isFreeUser(user, entitlements) && (
              <Grid item>
                <PassPremiumButton text={messages.passPremium} />
              </Grid>
            )}

            <Grid item>
              <Button
                color="primary"
                className={classes.button}
                onClick={() => {
                  setDrawer(false);
                  history.push(routes.getPathByName('profile'));
                }}
              >
                {isFreeUser(user, entitlements) ? (
                  <PersonIcon className={classes.buttonIcon} />
                ) : (
                  <img src="/logo_premium.webp" alt="premium" className={classes.menuPremiumLogo} />
                )}
                <FormattedMessage {...messages.profile} />
              </Button>
            </Grid>

            <Grid item>
              <Button
                color="primary"
                className={classes.button}
                onClick={() =>
                  updateBiker({ newTtripsNotifications: !propOr(false, 'newTtripsNotifications', user), _id: user._id })
                }
              >
                <EmailIcon className={classes.buttonIcon} />
                <FormattedMessage {...messages.tripsNotifications} />
                <Switch checked={propOr(false, 'newTtripsNotifications', user)} className={classes.switch} />
              </Button>
            </Grid>

            <Grid item>
              <Button
                color="primary"
                className={classes.button}
                onClick={() => {
                  setNotificationsRadius(true);
                  setDrawer(false);
                }}
              >
                <LocationOnIcon className={classes.buttonIcon} />
                <FormattedMessage {...messages.searchRadius} />
              </Button>
            </Grid>

            {/* <Grid item>
              <Button
                color="primary"
                className={classes.button}
                onClick={() => history.push(routes.getPathByName('Tutos'))}
              >
                <StarIcon className={classes.buttonIcon} />
                <FormattedMessage {...messages.tutorials} />
              </Button>
            </Grid> */}

            <Grid item>
              <Button
                color="primary"
                className={classes.button}
                onClick={() => history.push(routes.getPathByName('plus'))}
              >
                <SearchIcon className={classes.buttonIcon} />
                <FormattedMessage {...messages.plus} />
              </Button>
            </Grid>

            {user && user.role === ROLES.admin && (
              <Grid item>
                <Button
                  color="primary"
                  className={classes.button}
                  onClick={() => history.push(routes.getPathByName('backOffice'))}
                >
                  <SettingsIcon className={classes.buttonIcon} />
                  <FormattedMessage {...messages.backlog} />
                </Button>
              </Grid>
            )}
            {isNative && (
              <Grid item>
                <Button color="primary" className={classes.button} onClick={() => askExpoToShareComot()}>
                  <ShareIcon className={classes.buttonIcon} />
                  <FormattedMessage {...messages.shareComot} />
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button color="primary" className={classes.button} onClick={() => setLogoutDialog(true)}>
                <CloseIcon className={classes.buttonIcon} />
                <FormattedMessage {...messages.disconnect} />
              </Button>
            </Grid>
          </Grid>
        </>
      </SwipeableDrawer>

      <Dialog
        dialog={logoutDialog}
        title={intl.formatMessage(messages.dialogTitle)}
        text={intl.formatMessage(messages.logoutDialogText)}
        onOkActionName={intl.formatMessage(messages.confirm)}
        setDialog={setLogoutDialog}
        onOk={() => {
          logout();
          window.location.reload();
        }}
      />
      {notificationsRadius && (
        <SelectLocation notificationsRadius={notificationsRadius} setNotificationsRadius={setNotificationsRadius} />
      )}
    </Box>
  );
};

MainMenu.propTypes = {
  user: PropTypes.object,
  open: PropTypes.bool,
  setDrawer: PropTypes.func,
};

export default MainMenu;
