import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { map, compose, length } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { isFuture } from 'date-fns';
import BikerConfirmedToJoinTripCard from './BikerConfirmedToJoinTripCard';
import NewFriendRequestCard from './NewFriendRequestCard';
import FriendRequestAcceptedCard from './FriendRequestAcceptedCard';
import InvitationAccepted from './InvitationAccepted';
import InvitationRejected from './InvitationRejected';
import InvitationRecieved from './InvitationRecieved';
import { useConnect, useNnotificationsRadius } from './hooks';
import NoResults from '../NoResults';
import messages from './messages';
import DeletableCard from './DeletableCard';
import SelectLocation from './SelectLocation';
import {
  BIKER_CONFIRMED_TO_JOIN_TRIP,
  BIKER_REJECTED_TO_JOIN_TRIP,
  BIKER_REQUEST_TO_JOIN_TRIP,
  BIKER_CANCELED_PARTICIPATION,
  BIKER_JOINED_TRIP,
  TRIP_CREATED,
  TRIP_ENDED,
  TRIP_CANCELED,
  UNREAD_MESSAGES,
  TRIP_UPDATED,
  TRIP_REMINDER,
  FRIEND_REQUEST_RECIEVED,
  FRIEND_REQUEST_ACCEPTED,
  INVITATION_SENT,
  BIKER_CONFIRMED_INVITATION,
  BIKER_REJECTED_INVITATION,
} from '../../../lib/models/events';
import BikerRejectedToJoinTripCard from './BikerRejectedToJoinTripCard';
import TripCreatedCard from './TripCreatedCard';
import TripCanceledCard from './TripCanceledCard';
import Dialog from '../Dialog';
import BikerJoinedTripCard from './BikerJoinedTripCard';
import LeaderEndedTripCard from './LeaderEndedTripCard';
import BikerCanceledParticipationCard from './BikerCanceledParticipationCard';
import BikerRequestToJoinTrip from './BikerRequestToJoinTripCard';
import NewMessagesCard from './NewMessagesCard';
import TripUpdatedCard from './TripUpdatedCard';
import TripReminderCard from './TripReminderCard';
import useHeight from '../../hooks/useHeight';
import PassPremiumButton from '../Buttons/PassPremiumButton';

const drawerWidth = 360;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  icon: {
    marginBottom: '-7px',
  },
  drawer: {
    width: `${drawerWidth}px`,
    flexShrink: 0,
  },
  drawerPaper: ({ height }) => ({
    width: `${drawerWidth}px`,
    height: 'auto',
    maxHeight: `calc(${height}px  - ${theme.heights.appBar})`,
    top: theme.heights.appBar,
  }),
  drawerPaperRoot: {
    right: '0',
    position: 'absolute',
  },
  drawerHeader: {
    display: 'flex',
    position: 'sticky',
    top: '0',
    zIndex: ' 1500',
    background: ' #FFF',

    alignItems: 'center',
    padding: theme.spacing(1),
    ...theme.mixins.toolbar,
    color: theme.palette.grey[800],
    minHeight: 'auto',
  },
  containerStyle: {
    width: '25%',
  },
  delete: {
    maxWidth: '50px',
    width: '50px',
  },
  popover: {
    marginTop: '115px',
    width: '100%',
  },
  button: {
    fontSize: '10px',
    '& p': {
      fontSize: '12px',
    },
  },
  sticktoRight: {
    marginLeft: 'auto',
    padding: theme.spacing(1),
  },
  premiumBtnStyle: {
    width: theme.spacing(1),
    margin: 'auto',
    marginBottom: theme.spacing(2),
  },
}));

const Notifications = ({ open, setNotifications }) => {
  const height = useHeight();
  const classes = useStyles({ height });
  const {
    events,
    expirationNotification,
    trips,
    bikers,
    setEventAsRed,
    setAllEventsAsRed,
    confirm,
    reject,
    acceptRequest,
    rejectRequest,
    user,
    entitlements,
  } = useConnect();
  const [notificationsRadius, setNotificationsRadius] = useNnotificationsRadius();
  useEffect(() => {
    if (!open) return setAllEventsAsRed();
  }, [open]); //eslint-disable-line
  const intl = useIntl();
  const cardRenderer = event => {
    const props = {
      key: event._id,
      event,
      trip: trips[event.tripId],
      setEventAsRed,
      setNotifications,
    };
    switch (event.type) {
      case BIKER_CONFIRMED_TO_JOIN_TRIP:
        return (
          <DeletableCard event={event}>
            <BikerConfirmedToJoinTripCard source={bikers[event.leaderId]} {...props} />
          </DeletableCard>
        );
      case BIKER_REJECTED_TO_JOIN_TRIP:
        return (
          <DeletableCard event={event}>
            <BikerRejectedToJoinTripCard source={bikers[event.leaderId]} {...props} />
          </DeletableCard>
        );
      case TRIP_CREATED:
        return (
          <DeletableCard event={event}>
            <TripCreatedCard source={bikers[event.leaderId]} {...props} />
          </DeletableCard>
        );
      case TRIP_CANCELED:
        return (
          <DeletableCard event={event}>
            <TripCanceledCard source={bikers[event.leaderId]} {...props} />
          </DeletableCard>
        );
      case TRIP_UPDATED:
        return (
          <DeletableCard event={event}>
            <TripUpdatedCard source={bikers[event.leaderId]} {...props} />
          </DeletableCard>
        );
      case BIKER_JOINED_TRIP:
        return (
          <DeletableCard event={event}>
            <BikerJoinedTripCard source={bikers[event.bikerId]} {...props} />
          </DeletableCard>
        );
      case TRIP_ENDED:
        return (
          <DeletableCard event={event}>
            <LeaderEndedTripCard source={bikers[event.leaderId]} {...props} />
          </DeletableCard>
        );
      case BIKER_CANCELED_PARTICIPATION:
        return (
          <DeletableCard event={event}>
            <BikerCanceledParticipationCard source={bikers[event.bikerId]} {...props} />
          </DeletableCard>
        );
      case BIKER_REQUEST_TO_JOIN_TRIP:
        return (
          <DeletableCard event={event}>
            <BikerRequestToJoinTrip source={bikers[event.bikerId]} confirm={confirm} reject={reject} {...props} />
          </DeletableCard>
        );
      case TRIP_REMINDER:
        return (
          <DeletableCard event={event}>
            <TripReminderCard {...props} source={bikers[event.leaderId]} />
          </DeletableCard>
        );
      case BIKER_CONFIRMED_INVITATION:
        return (
          <DeletableCard event={event}>
            <InvitationAccepted source={bikers[event.bikerId]} {...props} />
          </DeletableCard>
        );

      case BIKER_REJECTED_INVITATION:
        return (
          <DeletableCard event={event}>
            <InvitationRejected source={bikers[event.bikerId]} {...props} />
          </DeletableCard>
        );

      case UNREAD_MESSAGES:
        return <NewMessagesCard key={event._id} event={event} {...props} />;
      case INVITATION_SENT:
        return (
          <DeletableCard event={event}>
            <InvitationRecieved
              source={bikers[event.leaderId]}
              biker={user}
              confirm={confirm}
              reject={reject}
              entitlements={entitlements}
              {...props}
            />
          </DeletableCard>
        );
      case FRIEND_REQUEST_RECIEVED:
        return (
          <NewFriendRequestCard
            key={event._id}
            source={bikers[event.bikerId]}
            acceptRequest={acceptRequest}
            rejectRequest={rejectRequest}
            {...props}
          />
        );
      case FRIEND_REQUEST_ACCEPTED:
        return <FriendRequestAcceptedCard key={event._id} source={bikers[event.bikerId]} {...props} />;
      default:
        return null;
    }
  };

  return (
    <>
      {notificationsRadius && (
        <SelectLocation notificationsRadius={notificationsRadius} setNotificationsRadius={setNotificationsRadius} />
      )}
      <Box className={classes.root} style={{ width: `${drawerWidth}px` }}>
        <Drawer
          anchor="right"
          onClose={() => setNotifications()}
          open={open}
          classes={{
            root: classes.drawerPaperRoot,
            paper: classes.drawerPaper,
          }}
        >
          <Grid container wrap="nowrap" justify="space-between" className={classes.drawerHeader}>
            <Grid item>
              <IconButton onClick={() => setNotifications()}>
                <Typography variant="body2" color="textSecondary">
                  <FormattedMessage {...messages.hide} />
                </Typography>
                <ChevronRightIcon />
              </IconButton>
            </Grid>

            <Button
              color="primary"
              className={classes.button}
              onClick={() => {
                setNotificationsRadius(true);
              }}
            >
              <Typography className={classes.sticktoRight}>
                <FormattedMessage {...messages.setSearchCenter} />
              </Typography>
            </Button>
          </Grid>

          <Divider />
          <Box>
            {length(events) ? (
              compose(map(event => <Box key={event._id}> {cardRenderer(event)} </Box>))(events)
            ) : (
              <NoResults message={intl.formatMessage(messages.noNotifications)} />
            )}
          </Box>
        </Drawer>
      </Box>

      {expirationNotification &&
        (!user.subscription || (user.subscription.endDate && !isFuture(new Date(user.subscription.endDate)))) && (
          <Dialog
            dialog={expirationNotification && !expirationNotification.readAt}
            title={
              isFuture(new Date(expirationNotification.expiresAt))
                ? intl.formatMessage(messages.dialogTitleWillEnd)
                : intl.formatMessage(messages.dialogTitlended)
            }
            text={intl.formatMessage(messages.dialogText)}
            custromAction={<PassPremiumButton buttonStyle={classes.premiumBtnStyle} />}
            dialogStyle={classes.dialogStyle}
            onClose={() => setEventAsRed(expirationNotification._id)}
          />
        )}
    </>
  );
};

Notifications.propTypes = {
  open: PropTypes.bool,
  setNotifications: PropTypes.func,
};

export default Notifications;
