import axios from 'axios';
import { prop } from 'ramda';

export default ({ endPoint, bearer }) => ({
  startSubscriptionProcess: () =>
    axios.get(endPoint(`/api/subscriptions/startSubscriptionProcess`), { headers: bearer() }).then(prop('data')),

  cancel: () => axios.get(endPoint(`/api/subscriptions/cancel`), { headers: bearer() }).then(prop('data')),
  checkSubscription: () =>
    axios.get(endPoint(`/api/subscriptions/checkSubscription`), { headers: bearer() }).then(prop('data')),
});
