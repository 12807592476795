import { compose, reduce, propOr, assoc } from 'ramda';
import { getcurrentTripId } from '../selectors/ridingTrip';
import trips from './trips'; // eslint-disable-line
import routes from './routes'; // eslint-disable-line
import trippers from './trippers'; // eslint-disable-line
import locations from './locations'; // eslint-disable-line
import { TRIP_UPDATED } from './trips'; // eslint-disable-line
import * as wheelsDM from '../../lib/models/wheels';
import { ROADTRIP_COMING_SOON, ROADTRIP_STARTED } from '../../lib/models/events';

const TRIPS_LOADED = '@ridingTrip:TRIPS_LOADED';
const TRIP_DIRECTION = '@ridingTrip:TRIP_DIRECTION';
const SHARE_LOCATION = '@ridingTrip:SHARE_LOCATION';

const reducer = (state = { shareLocation: false }, action = {}) => {
  let trips;
  switch (action.type) {
    case ROADTRIP_STARTED:
    case ROADTRIP_COMING_SOON:
      return {
        ...state,
        tripId: action.tripId,
      };
    case TRIP_UPDATED:
      return {
        ...state,
        trips: assoc(action.trip._id, action.trip, state.trips),
      };
    case TRIPS_LOADED:
      trips = compose(
        reduce((acc, v) => ({ ...acc, [v._id]: v }), {}),
        propOr([], 'trips'),
      )(action);
      return { ...state, trips };
    case TRIP_DIRECTION:
      return {
        ...state,
        directions: action.directions,
      };
    case SHARE_LOCATION:
      return {
        ...state,
        shareLocation: action.shareLocation,
      };
    default:
      return {
        ...state,
      };
  }
};

const setActiveTripInfo = id => (dispatch, getState) => {
  const state = getState();
  const tripId = id || getcurrentTripId(state);
  dispatch(
    trippers.actions.getTrippers(tripId, [
      wheelsDM.STATUS.riding,
      wheelsDM.STATUS.accepted,
      wheelsDM.STATUS.suspended,
      wheelsDM.STATUS.pending,
      wheelsDM.STATUS.done,
      wheelsDM.STATUS.archived,
      wheelsDM.STATUS.invited,
    ]),
  );
  if (tripId) {
    dispatch(locations.actions.loadTrippersLocations(tripId));
    dispatch(trips.actions.loadOne(tripId)).then(trip => {
      dispatch(routes.actions.loadOne(trip.routeId));
    });
  }
};

const setDirections = directions => dispatch => {
  dispatch({
    type: TRIP_DIRECTION,
    directions,
  });
};
const setShareLocation = shareLocation => dispatch => {
  dispatch({
    type: SHARE_LOCATION,
    shareLocation,
  });
};
const actions = { setActiveTripInfo, setDirections, setShareLocation };

export default { reducer, actions };
