import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { path, pathOr, prop } from 'ramda';
import { getEvents, getLastPremiumExpiration, getUnredEventsCount } from '../../selectors/events';
import { getUser, getEntitlements } from '../../selectors/auth';
import { getBikers, getBiker } from '../../selectors/bikers';
import { getTripsObj } from '../../selectors/trips';
import { getLocation } from '../../selectors/location';
import actions from '../../actions';
import { getGeoCoder } from '../../mapbox';
import { getPendingRequest } from '../../selectors/core';

export const useConnect = () => {
  const dispatch = useDispatch();
  return {
    events: useSelector(getEvents),
    expirationNotification: useSelector(getLastPremiumExpiration),
    bikers: useSelector(getBikers),
    biker: useSelector(getBiker(prop('_id', useSelector(getUser)))),
    trips: useSelector(getTripsObj),
    user: useSelector(getUser),
    entitlements: useSelector(getEntitlements),
    location: useSelector(getLocation),
    unredEventsCount: useSelector(getUnredEventsCount),
    setLocation: position => dispatch(actions.bikers.setLocation(position)),
    setEventAsRed: id => dispatch(actions.events.setEventAsRed(id)),
    setAllEventsAsRed: () => dispatch(actions.events.setAllEventsAsRed()),
    confirm: (tripId, bikerId) => dispatch(actions.trippers.confirm(tripId, bikerId)),
    reject: (tripId, bikerId) => dispatch(actions.trippers.reject(tripId, bikerId)),
    removeEvent: event => dispatch(actions.events.removeEvent(event)),
    remove: event => dispatch(actions.events.remove(event)),
    loadOne: id => dispatch(actions.bikers.loadOne(id)),
    updateNotificationsRadius: pos => dispatch(actions.bikers.updateNotificationsRadius(pos)),
    update: biker => dispatch(actions.bikers.update(biker)),
    acceptRequest: id => dispatch(actions.friends.acceptRequest(id)),
    rejectRequest: id => dispatch(actions.friends.rejectRequest(id)),
    pendingRequests: useSelector(getPendingRequest),
  };
};

export const useNnotificationsRadius = () => {
  const [notificationsRadius, setNotificationsRadius] = useState(false);
  return [notificationsRadius, setNotificationsRadius];
};

export const usePopover = () => {
  const [popover, setPopover] = useState(null);
  return [popover, setPopover];
};

export const useViewport = () => {
  const [viewport, setViewport] = useState({
    zoom: 4.8,
    bearing: 0,
    pitch: 0,
    transitionDuration: 0,
    longitude: 3.224875,
    latitude: 46.8501821,
  });
  return [viewport, setViewport];
};

export const usePremiumDrawer = () => {
  const [premiumDrawer, setPremiumDrawer] = useState(false);
  return [premiumDrawer, setPremiumDrawer];
};

export const useGeoCoder = position => {
  const [geoCoder, setGeoCoder] = useState('');
  const [queryResults, setQueryResults] = useState([]);

  const updateResults = input => {
    setGeoCoder(pathOr('', ['target', 'value'], input));
    if (path(['target', 'value'], input)) {
      getGeoCoder(path(['target', 'value'], input), 10, `${position[0]},${position[1]}`).then(res =>
        setQueryResults(res.data.features),
      );
    }
  };

  return {
    geoCoder,
    setGeoCoder,
    queryResults,
    updateResults,
  };
};
