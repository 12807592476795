import { compose, propOr, assoc, reduce, set, lensPath, path } from 'ramda';
import { request } from './utils';
import * as wheelsDM from '../../lib/models/wheels';
import { BIKER_RIDING } from '../../lib/models/events';
import { TRIP_REMOVED } from './myTrips'; // eslint-disable-line
import { SIGNED_OUT, LAST_TRIP_DATE, DEC_TRIPS_COUNT, refreshUser } from './auth'; // eslint-disable-line
import { postMessage } from './native'; // eslint-disable-line
import { NATIVE_ACTIONS } from '../../lib/models/native';
import { isPremiumUser } from '../../lib/models/bikers';
import { getUser, getEntitlements } from '../selectors/auth';
const TRIPPERS_LOADED = '@tripper:TRIPPERS_LOADED';
export const TRIPPER_ENDED = '@tripper:TRIPPER_ENDED';
export const TRIPPER_ADDED = '@tripper:TRIPPER_ADDED';
export const TRIPPERS_ACCEPTED = '@tripper:TRIPPERS_ACCEPTED';
export const TRIPPERS_REJECTED = '@tripper:TRIPPERS_REJECTED';
export const TRIPPERS_RIDING = '@tripper:TRIPPERS_RIDING';
export const TRIPPER_CANCELED = '@tripper:TRIPPER_CANCELED';

const reducer = (state = {}, action = {}) => {
  let data;
  switch (action.type) {
    case TRIPPERS_LOADED:
      data = compose(
        reduce((acc, v) => ({ ...acc, [v._id]: v }), state.data),
        propOr([], 'trippers'),
      )(action);
      return { ...state, data };
    case BIKER_RIDING:
      return {
        ...state,
        data: assoc(
          action._id,
          { ...state.data[action._id], updatedAt: action.createdAt, status: wheelsDM.STATUS.riding },
          state.data,
        ),
      };
    case TRIPPERS_RIDING:
      return {
        ...state,
        data: set(lensPath([action.id, 'status']), wheelsDM.STATUS.riding, state.data),
      };
    case TRIPPERS_ACCEPTED:
      return {
        ...state,
        data: set(lensPath([action.tripper._id, 'status']), wheelsDM.STATUS.accepted, state.data),
      };
    case TRIPPERS_REJECTED:
      return {
        ...state,
        data: set(lensPath([action.tripper._id, 'status']), wheelsDM.STATUS.rejected, state.data),
      };
    case TRIPPER_CANCELED:
    case TRIPPER_ADDED:
      return {
        ...state,
        data: assoc(path(['tripper', '_id'], action), action.tripper, state.data),
      };
    default:
      return state;
  }
};

const getTrippers = (id, statuses) => dispatch =>
  request(dispatch, { method: 'trips:getTrippers', id, statuses }).then(trippers => {
    dispatch({ type: TRIPPERS_LOADED, trippers });
    return trippers;
  });

const loadOne = id => dispatch =>
  request(dispatch, { method: 'trippers:loadOne', id }).then(tripper => {
    dispatch({ type: TRIPPER_ADDED, tripper });
    return tripper;
  });

const join = (tripId, isVisitor = false) => (dispatch, getState) => {
  const state = getState();
  const user = getUser(state);
  const entitlements = getEntitlements(state);
  return request(dispatch, { method: 'trippers:join', tripId, isVisitor, entitlements })
    .then(() => {
      if (!isPremiumUser(user, entitlements)) dispatch(postMessage({ type: NATIVE_ACTIONS.SHOW_ADD }));
      return dispatch({ type: LAST_TRIP_DATE });
    })
    .then(() => dispatch(refreshUser()));
};

const reject = (tripId, bikerId) => dispatch => {
  request(dispatch, { method: 'trippers:reject', tripId, bikerId }).then(tripper =>
    dispatch({ type: TRIPPERS_REJECTED, tripper }),
  );
};
const confirm = (tripId, bikerId) => dispatch =>
  request(dispatch, { method: 'trippers:confirm', tripId, bikerId })
    .then(tripper => dispatch({ type: TRIPPERS_ACCEPTED, tripper }))
    .then(() => dispatch(refreshUser()));

const cancel = trip => dispatch =>
  request(dispatch, { method: 'trippers:cancel', tripId: trip._id }).then(
    tripper => dispatch({ type: TRIPPER_CANCELED, tripper }),
    dispatch({ type: DEC_TRIPS_COUNT }),
  );

const ride = tripId => dispatch => {
  return request(dispatch, { method: 'trippers:ride', tripId }).then(tripper => {
    dispatch({ type: TRIPPERS_RIDING, id: tripper._id });
    return tripper;
  });
};

const end = id => dispatch =>
  // eslint-disable-next-line
  request(dispatch, { method: 'trippers:end', id }).then(trip => {
    dispatch({ type: TRIPPER_ENDED, trip });
    return trip;
  });

const trippersLoaded = trippers => dispatch => {
  dispatch({ type: TRIPPERS_LOADED, trippers });
  return trippers;
};

const remove = trip => dispatch => {
  return request(dispatch, { method: 'trippers:remove', tripId: trip._id }).then(tripper => {
    dispatch({ type: TRIP_REMOVED, id: trip._id });
    return tripper;
  });
};

const actions = { getTrippers, join, confirm, reject, cancel, ride, trippersLoaded, end, loadOne, remove };
export default { reducer, actions };
