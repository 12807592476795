import { pathOr } from 'ramda';
import api from '../../lib/api';
import { NATIVE_ACTIONS } from '../../lib/models/native';
import { request } from './utils';
import allActions from '../actions'; //eslint-disable-line
import { BIKER_UPDATED } from './bikers'; //eslint-disable-line
import { getUser, getEntitlements } from '../selectors/auth';
import { isPremiumUser } from '../../lib/models/bikers';
export const ADD_TOKEN = '@auth:ADD_TOKEN';
export const SIGNED_IN = '@auth:SIGNED_IN';
export const SIGNED_OUT = '@auth:SIGNED_OUT';
export const GET_CLIENT_SECRET = '@auth:GET_CLIENT_SECRET';
export const LAST_TRIP_DATE = '@auth:LAST_TRIP_DATE';
export const DEC_TRIPS_COUNT = '@auth:DEC_TRIPS_COUNT';
export const REFRESH_USER = '@auth:REFRESH_USER';
export const ADD_USER_ENTITLEMENTS = '@auth:ADD_USER_ENTITLEMENTS';
export const TOKEN = 'comot:token';
export const SUBSCRIPTION_CREATED = '@auth:SUBSCRIPTION_CREATED';
export const SUBSCRIPTION_CANCELED = '@auth:SUBSCRIPTION_CANCELED';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_TOKEN:
      return {
        ...state,
        token: action.token,
      };
    case SIGNED_IN:
      return {
        ...state,
        user: action.user,
        token: action.token,
      };
    case ADD_USER_ENTITLEMENTS:
      return {
        ...state,
        entitlements: action.entitlements,
      };
    case REFRESH_USER:
      return {
        ...state,
        user: action.user,
      };
    case LAST_TRIP_DATE:
      return {
        ...state,
        user: {
          ...state.user,
          lastTripDate: new Date(),
        },
      };
    case DEC_TRIPS_COUNT:
      return {
        ...state,
        user: {
          ...state.user,
          tripsCount: pathOr(1, ['user', 'tripsCount'], state) - 1,
        },
      };
    case GET_CLIENT_SECRET:
      return {
        ...state,
        clientSecret: action.clientSecret,
      };
    case BIKER_UPDATED:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.biker,
        },
      };
    case SIGNED_OUT:
      return {};
    default:
      return state;
  }
};

const addToken = token => ({ type: ADD_TOKEN, token });

const signInWithFacebook = data => dispatch =>
  api.auth.signInWithFacebook(data).then(({ token, user }) => {
    dispatch(signedIn(token, user));
  });

const signUp = data => dispatch =>
  api.auth.signUp(data).then(({ token, user }) => {
    dispatch(signedIn(token, user));
  });

const signInWithEmail = data => (dispatch, getState) => {
  const state = getState();
  const user = getUser(state);
  const gentitlements = getEntitlements(state);
  if (!isPremiumUser(user, gentitlements)) dispatch(allActions.native.postMessage({ type: NATIVE_ACTIONS.SHOW_ADD }));
  return api.auth.signInWithEmail(data).then(({ token, user }) => dispatch(signedIn(token, user)));
};

const signInWithGoogle = data => dispatch =>
  api.auth.signInWithGoogle(data).then(({ token, user }) => {
    dispatch(signedIn(token, user));
  });

const resetPasswordEmail = data => () => api.auth.resetPasswordEmail(data);
const resetPasswordRequest = data => () => api.auth.resetPasswordRequest(data);
const editPassword = data => dispatch => request(dispatch, { method: 'auth:editPassword', ...data });
const confirmationMail = () => dispatch => request(dispatch, { method: 'auth:confirmationMail' });
const confirmAccount = data => dispatch =>
  request(dispatch, { method: 'auth:confirmAccount', ...data }).then(user =>
    dispatch({ type: BIKER_UPDATED, biker: user }),
  );

const signOut = () => dispatch => {
  dispatch({ type: SIGNED_OUT });
  localStorage.removeItem(TOKEN);
  dispatch(allActions.native.postMessage({ type: NATIVE_ACTIONS.REMOVETOKEN_TOKEN }));
};

const signedIn = (token, user) => dispatch => {
  localStorage.setItem(TOKEN, token);
  dispatch({ type: SIGNED_IN, token, user });
  dispatch(allActions.events.loadAll());
  dispatch(allActions.native.postMessage({ type: NATIVE_ACTIONS.SET_TOKEN, payload: { token, user } }));
};

const getPremiumMonthlySubscription = () => dispatch =>
  api.auth.createMonthPaymentIntent().then(({ clientSecret }) => {
    dispatch({ type: GET_CLIENT_SECRET, clientSecret });
    return { clientSecret };
  });
const getPremiumYearlySubscription = () => dispatch =>
  api.auth.createYearPaymentIntent().then(({ clientSecret }) => {
    dispatch({ type: GET_CLIENT_SECRET, clientSecret });
    return { clientSecret };
  });

const startSubscriptionProcess = ({ subscriptionType }) => dispatch =>
  request(dispatch, { method: 'subscriptions:startSubscriptionProcess', subscriptionType }).then(data => {
    dispatch({ type: SUBSCRIPTION_CREATED, data });
    return data;
  });

const cancelPremium = () => dispatch =>
  request(dispatch, { method: 'subscriptions:cancel' }).then(data => {
    dispatch(refreshUser());
    dispatch({ type: SUBSCRIPTION_CANCELED, data });
    return data;
  });

const checkSubscription = () => dispatch => request(dispatch, { method: 'subscriptions:checkSubscription' });

export const refreshUser = (premiumData = {}) => dispatch =>
  api.auth.getUser().then(user => dispatch({ type: REFRESH_USER, user: { ...user, premiumData } }));

const actions = {
  addToken,
  signInWithFacebook,
  getPremiumMonthlySubscription,
  getPremiumYearlySubscription,
  resetPasswordEmail,
  resetPasswordRequest,
  signInWithGoogle,
  signedIn,
  signOut,
  signUp,
  signInWithEmail,
  editPassword,
  confirmationMail,
  confirmAccount,
  startSubscriptionProcess,
  refreshUser,
  cancelPremium,
  checkSubscription,
};

export default { reducer, actions };
