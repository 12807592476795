import React from 'react';
import { node } from 'prop-types';
import { useConnect } from './hooks';
import Home from '../Home';
import { ROLES, isPremiumUser, tripsLimitReached } from '../../../lib/models/bikers';

const Auth = ({ children, isAdminRequired, isPremiumRequired }) => {
  const { token, onAuth, user, entitlements } = useConnect();
  if (
    !token ||
    (isAdminRequired() && user.role !== ROLES.admin) ||
    (isPremiumRequired() && !isPremiumUser(user, entitlements) && tripsLimitReached(user))
  )
    return <Home onAuth={onAuth} />;
  return children;
};

Auth.propTypes = {
  children: node.isRequired,
};

export default Auth;
